import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Carousel, Row, Spin } from "antd";
import { withRouter, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ArrowRightOutlined } from "@ant-design/icons";
//import { Link } from "react-router-dom";
import SearchBarHome from "./SearchBarHome";
import HeaderAlternative from "./HeaderAlternative";

class SharedLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dotPosition: "bottom",
      pexUnits: props.pexUnits,
      pexUnits: [],
      AllPexUnit: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    fetch(
      "https://www.vcsappcloud.com/mtgroup/api/pexunit/findallpexunitclient",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      const result = await res.json();
      this.setState({
        pexUnits: result.allPexUnit,
        loading: false,
      });
    });
  }
  getFilters(filters) {
    this.props.stockfilters(filters);
  }

  renderSearchBar() {
    const { dotPosition } = this.state;
    const { pexUnits } = this.props;
    return (
      <div>
        <section className="block-sl desktop-view">
          <div className="container-custom">
            <div className="row">
              <div>
                <Carousel
                  autoplay
                  dotPosition={dotPosition}
                  dots={true}
                  style={{
                    // width: "100vw",

                    // position: "absolute",
                    left: 0,
                    objectFit: "cover",
                  }}
                >
                  {" "}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage &&
                      pexUnits.pageName === "Haval Controller" ? (
                      <span>
                        <div className="overlay"></div>
                        <img
                          // className='banner-img-home'
                          style={{
                            width: "100vw",
                            height: "100vh",

                            objectFit: "cover",
                          }}
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage}`}
                          alt="logo"
                        />
                      </span>
                    ) : null;
                  })}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage2 &&
                      pexUnits.pageName === "Haval Controller" ? (
                      <span>
                        <div className="overlay"></div>
                        <img
                          // className='banner-img-home'
                          style={{
                            width: "100vw",
                            height: "100vh",
                            objectFit: "cover",
                          }}
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage2}`}
                          alt="logo"
                        />
                      </span>
                    ) : null;
                  })}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage3 &&
                      pexUnits.pageName === "Haval Controller" ? (
                      <span>
                        <div className="overlay"></div>
                        <img
                          // className='banner-img-home'
                          style={{
                            width: "100vw",
                            height: "100vh",
                            objectFit: "cover",
                          }}
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage3}`}
                          alt="logo"
                        />
                      </span>
                    ) : null;
                  })}
                  {/*<div className='col-sm-6 col-md-6 col-lg-6'>
                  <img
                    // className='banner-img-home'
                    style={{ width: "50em" }}
                    src={`${process.env.PUBLIC_URL}/images/home-banner-2.png`}
                    alt='logo'
                  />
                </div>*/}
                </Carousel>
              </div>
              <div
                className="col-sm-12 col-md-12 col-lg-12"
                style={{
                  zIndex: 10,
                  textAlign: "left",
                  maxWidth: "100em",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  margin: "auto",
                  objectFit: "contain",
                  top: "10.5vw",
                }}
              >
                <div className="banner-item-home banner-2x-home no-bg color-inher-home">
                  <h2
                    className="f-weight-600"
                    style={{
                      fontSize: "50px",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      color: "white",
                      marginBottom: "0.3em",
                      textTransform: "none",
                    }}
                  >
                    <br />
                    Welcome To Haval Lichtenburg
                  </h2>
                  {/* <p
                    style={{
                      marginBottom: "-0.5em",
                      marginTop: "0em", textAlign: "left",
                      whiteSpace: "nowrap", color: "white"
                    }}
                  >
                    {this.state.pexUnits.slice(0, 1).map((pexUnits, index) => {
                      return pexUnits.homeTitle ? (
                        <span className='highlight'> {pexUnits.homeTitle}</span>
                      ) : null;
                    })}
                  </p> */}
                  <div
                    style={{
                      textAlign: "left",
                      borderTop: "1px solid rgba(255, 255, 255, 0.479)",
                      width: "46em",
                    }}
                  >
                    <Link
                      to={"/haval-range"}
                      className="ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30"
                      style={{ textTransform: "none" }}
                    >
                      EXPLORE OUR RANGE{" "}
                      <ArrowRightOutlined style={{ marginLeft: "1em" }} />
                    </Link>
                    <Link
                      to="/Service"
                      className="ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30"
                      style={{
                        textTransform: "none",
                        marginLeft: "1em",
                      }}
                    >
                      BOOK A SERVICE{" "}
                      <ArrowRightOutlined style={{ marginLeft: "1em" }} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>{" "}
        <section className="block-sl mobile-view" style={{ width: "100vw" }}>
          <div className="container-custom">
            <div className="row">
              <div>
                <Carousel
                  autoplay
                  dotPosition={dotPosition}
                  dots={true}
                  style={{
                    // width: "100vw",

                    // position: "absolute",
                    left: 0,
                    objectFit: "cover",
                  }}
                >
                  {" "}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage &&
                      pexUnits.pageName === "Haval Controller" ? (
                      <span>
                        <div className="overlay"></div>
                        <img
                          // className='banner-img-home'
                          style={{
                            width: "100vw",
                            height: "70vh",

                            objectFit: "cover",
                          }}
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage}`}
                          alt="logo"
                        />
                      </span>
                    ) : null;
                  })}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage2 &&
                      pexUnits.pageName === "Haval Controller" ? (
                      <span>
                        <div className="overlay"></div>
                        <img
                          // className='banner-img-home'
                          style={{
                            width: "100vw",
                            height: "70vh",
                            objectFit: "cover",
                            minHeight: "40em",
                          }}
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage2}`}
                          alt="logo"
                        />
                      </span>
                    ) : null;
                  })}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage3 &&
                      pexUnits.pageName === "Haval Controller" ? (
                      <span>
                        <div className="overlay"></div>
                        <img
                          // className='banner-img-home'
                          style={{
                            width: "100vw",
                            height: "70vh",
                            objectFit: "cover",
                            minHeight: "40em",
                          }}
                          src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage3}`}
                          alt="logo"
                        />
                      </span>
                    ) : null;
                  })}
                  {/*<div className='col-sm-6 col-md-6 col-lg-6'>
                  <img
                    // className='banner-img-home'
                    style={{ width: "50em" }}
                    src={`${process.env.PUBLIC_URL}/images/home-banner-2.png`}
                    alt='logo'
                  />
                </div>*/}
                </Carousel>
              </div>
              <div
                className="col-sm-12 col-md-12 col-lg-12"
                style={{
                  zIndex: 10,
                  textAlign: "left",
                  maxWidth: "100em",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  margin: "auto",
                  objectFit: "contain",
                  top: "46vw",
                }}
              >
                <div className="banner-item-home banner-2x-home no-bg color-inher-home">
                  <h2
                    className="f-weight-600"
                    style={{
                      fontSize: "35px",
                      textAlign: "left",

                      color: "white",
                      marginBottom: "0.3em",
                      textTransform: "none",
                    }}
                  >
                    <br />
                    Welcome To Haval Lichtenburg
                  </h2>
                  {/* <p
                    style={{
                      marginBottom: "-0.5em",
                      marginTop: "0em", textAlign: "left",
                      whiteSpace: "nowrap", color: "white"
                    }}
                  >
                    {this.state.pexUnits.slice(0, 1).map((pexUnits, index) => {
                      return pexUnits.homeTitle ? (
                        <span className='highlight'> {pexUnits.homeTitle}</span>
                      ) : null;
                    })}
                  </p> */}
                  <div
                    style={{
                      textAlign: "left",
                      borderTop: "1px solid rgba(255, 255, 255, 0.479)",
                      width: "23em",
                    }}
                  >
                    <Link
                      to={"/haval-range"}
                      className="ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30"
                      style={{ textTransform: "none" }}
                    >
                      EXPLORE
                    </Link>
                    <Link
                      to="/Service"
                      className="ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30"
                      style={{
                        textTransform: "none",
                        marginLeft: "1em",
                      }}
                    >
                      BOOK SERVICE{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <SearchBarHome filters={this.getFilters.bind(this)} /> */}
      </div>
    );
  }
  static propTypes = {
    location: PropTypes.object.isRequired,
  };
  render() {
    const { location } = this.props;
    return (
      <Spin spinning={this.state.loading}>
        <div
          id="wrap"
          // className='color1-inher'
        >
          <Header />

          {location.pathname === "/home" || location.pathname === "/"
            ? this.renderSearchBar()
            : null}
          <div id="wrap-body" className="p-t-lg-45">
            <div className="container-custom">{this.props.children}</div>
          </div>
          <Footer />
        </div>
      </Spin>
    );
  }
}
export default withRouter(SharedLayout);
